
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { enviornmentAperture } from 'environments/environment';
import { map } from 'rxjs/operators';
import { CommonDataService } from 'app/common-data.service';
@Injectable()
export class ResourceService {
  parentPracticeID: any;
  resourcesCodeDTO: { resourceCode: string; };
  constructor(private http: HttpClient, private CommonService: CommonDataService) {}

  initAddResource(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/initAddResource');
  }
  
  saveResourceDetails(data): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/resources/createResource', data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  saveBrowserDetails(data): Observable<any> {
    return this.http.put(enviornmentAperture.apiUrl + 'api/v1/resources/updateResourceBrowser', data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  saveResumeDetails(data): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/resources/postResumedetails', data  , {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  getResourceDetails(resourceCode:any, isCoded?): Observable<any> {
    if (!isCoded) {
      this.resourcesCodeDTO = {
        resourceCode: this.CommonService.encrypt(resourceCode)
      }
    } else {
      this.resourcesCodeDTO = {
        resourceCode: resourceCode
      }
    }
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/resources/getResourceById', this.resourcesCodeDTO, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  getResourceResume(resourceCode): Observable<any> {
    const resourcesCodeDTO = {
      resourceCode: this.CommonService.encrypt(resourceCode)
    }
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/resources/getResumeByResourceCode', resourcesCodeDTO, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  exportResourceResumeDoc(resourceCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/downloadResumeInWord/' + this.CommonService.encrypt(resourceCode), { responseType: 'blob' });
  }

  exportResourceResumePdf(resourceCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/downloadResumeInPdf/' + this.CommonService.encrypt(resourceCode), { responseType: 'blob' });
  }
  mfadownloadReport(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/cloud/download/file?name=Registering Microsoft Authenticator App_09Oct21.pdf', { responseType: 'blob' });
  }

  searchResource(searchValue): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/searchAllResources?searchParam=' + searchValue);
  }

  onBoardResource(obBoardData): Observable<any> {
    return this.http.put(enviornmentAperture.apiUrl + 'api/v1/resources/onBoard', obBoardData, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  getCurrentProjectsOfResource(resourceCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/getCurrentProjectsOfResource/' + resourceCode);
  }

  updateCosting(costingData): Observable<HttpResponse<any>> {
    return this.http.put(enviornmentAperture.apiUrl + 'api/v1/resources/updateCost', costingData, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  didNotJoin(onBoaringData, resourceCode): Observable<HttpResponse<any>> {
    return this.http
      .put(enviornmentAperture.apiUrl + 'api/v1/resources/markDidNotJoin/' + this.CommonService.encrypt(resourceCode), onBoaringData, {})
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }));
  }

  relocate(relocateData): Observable<HttpResponse<any>> {
    return this.http
      .put(enviornmentAperture.apiUrl + 'api/v1/resources/relocate/' +this.CommonService.encrypt(relocateData.resourceCode) + '?location=' + relocateData.locationId, {})
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }));
  }

  putOnLongLeave(leaveData, resourceCode): Observable<HttpResponse<any>> {
    return this.http
      .put(enviornmentAperture.apiUrl + 'api/v1/resources/putOnLongLeave/' + this.CommonService.encrypt(resourceCode), leaveData, {})
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }));
  }

  putOnResignation(resignationData, resourceCode): Observable<HttpResponse<any>> {
    return this.http
      .put(enviornmentAperture.apiUrl + 'api/v1/resources/putOnResignation/' + this.CommonService.encrypt(resourceCode), resignationData, {})
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }));
  }

  validateResource(date, resourceCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/validate/' + this.CommonService.encrypt(resourceCode) + '?date=' + date);
  }

  terminate(data, resourceCode): Observable<HttpResponse<any>> {
    return this.http.put(enviornmentAperture.apiUrl + 'api/v1/resources/terminated/' + this.CommonService.encrypt(resourceCode), data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  abscond(data, resourceCode): Observable<HttpResponse<any>> {
    return this.http.put(enviornmentAperture.apiUrl + 'api/v1/resources/abscond/' + this.CommonService.encrypt(resourceCode), data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  reActive(data, resourceCode): Observable<HttpResponse<any>> {
    return this.http.put(enviornmentAperture.apiUrl + 'api/v1/resources/active/' + this.CommonService.encrypt(resourceCode), data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  updateResource(resourceData): Observable<HttpResponse<any>> {
    return this.http.put(enviornmentAperture.apiUrl + 'api/v1/resources/updateResource', resourceData, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  getSearchMetaData(): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/searchMetaData');
  }

  advanceSearch(url): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + url);
  }

  exportReports(url): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + url, { responseType: 'blob' });
  }

  deleteUserFromKeyCloak(): Observable<any> {
    return this.http.delete(enviornmentAperture.apiUrl + 'api/v1/resources/deleteUsersInKeycloak');
  }

  deleteResourceFromKeycloak(email): Observable<any> {
    return this.http.delete(enviornmentAperture.apiUrl + 'api/v1/resources/deleteResourceFromKeycloakServer' + '?email=' + email);
  }

  exportBrowserReport(): Observable<any> {
    let construct_uri = '';
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/exportBrowserReport?' + construct_uri, { responseType: 'blob' });
  }

  autoPopulateEmail(searchParam): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/autoCompleteEmail/' + searchParam);
  }

  getResourceHistory(resourceCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/audit/resource/' + this.CommonService.encrypt(resourceCode));
  }

  getResourceCostHistory(resourceCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/getcosthistory/' + this.CommonService.encrypt(resourceCode));
  }

  updateResourceDetails(resourceCode, resourceData): Observable<HttpResponse<any>> {
    return this.http
      .patch(enviornmentAperture.apiUrl + 'api/v1/resources/partialUpdate/' + this.CommonService.encrypt(resourceCode), resourceData, {})
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }));
  }

  updatedHrAllocation(resourcecode,payload,id): Observable<any> {
    return this.http
      .put(enviornmentAperture.apiUrl + 'api/v1/allocations/updateAllocationHr',payload,{})
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }));
  }

  deleteHRAllocation(id): Observable<any> {
    return this.http.delete(enviornmentAperture.apiUrl + 'api/v1/allocations/deleteAllocationHr' + '?id=' + id);
  }

  updateResourceCostDetails(resourceCode, resourceData): Observable<HttpResponse<any>> {
    return this.http
      .patch(enviornmentAperture.apiUrl + 'api/v1/resources/partialCostUpdate/' + this.CommonService.encrypt(resourceCode), resourceData, {})
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }));
  }

  reviseCost(resourceCode, data): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/resources/reviseCost/' + this.CommonService.encrypt(resourceCode), data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  saveImage(resourceCode, imageData): Observable<HttpResponse<any>> {
    return this.http
      .put(enviornmentAperture.apiUrl + 'api/v1/resources/uploadImage/' + this.CommonService.encrypt(resourceCode), imageData, {})
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }));
  }

  updateResume(resourceCode, resumeData): Observable<HttpResponse<any>> {
    return this.http
      .put(enviornmentAperture.apiUrl + 'api/v1/resources/uploadResume/' + this.CommonService.encrypt(resourceCode), resumeData, {})
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }));
  }

  addSkill(data): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + '' + data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  terminatedLastAllocation(resourceCode, lastDate?): Observable<any> {
    if (lastDate) {
    return this.http.get(enviornmentAperture.apiUrl +  'api/v1/allocations/terminatedLastAllocation?date=' + lastDate + '&resourceCode=' + this.CommonService.encrypt(resourceCode));
    } else {
      return this.http.get(enviornmentAperture.apiUrl +  'api/v1/allocations/terminatedLastAllocation?resourceCode=' + this.CommonService.encrypt(resourceCode));
    }
  }

  saveBlockResource(data): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/resources/blockResourceForAccount', data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  updateBlockResource(data): Observable<any> {
    return this.http.put(enviornmentAperture.apiUrl + 'api/v1/resources/updateblockResourceForAccount', data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  getBlockResourceDetails(resourceCode): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/getBlockResourceForAccount/' + this.CommonService.encrypt(resourceCode));
  }

  getBlockResourceDetailsById(id): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/resources/getBlockResourceDetail/' + this.CommonService.encrypt(id));
  }

  deleteBlockResourceRecord(id, resourceCode, deleteAll): Observable<any> {
    return this.http.delete(enviornmentAperture.apiUrl + 'api/v1/resources/deleteBlockResourceDetail/'+id+'/'+this.CommonService.encrypt(resourceCode)+'/'+deleteAll);
  }

  getPositionInfo(resourceCode): Observable<any> {
    const resourcesCodeDTO = {
      resourceCode: this.CommonService.encrypt(resourceCode)
    }
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/additional/additionalInfo', resourcesCodeDTO, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  getAddAllocationMetadatahr(id): Observable<any> {
    return this.http.get(enviornmentAperture.apiUrl + 'api/v1/allocations/getAllocationHr?id=' + id, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  addhrAllocation(payload, id): Observable<any> {
    return this.http
      .post(enviornmentAperture.apiUrl + 'api/v1/allocations/addAllocationHr?accountCode=' + payload.resource_code+ '&endDate=' + payload.end_date + '&id=' + id + '&occupancy='+ payload.occupancy + '&resourceCode=' + payload.account_code + '&startDate=' + payload.start_date, {})
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }));
  }
  addhrAllocationarray(payload,id): Observable<any> {
    return this.http
      .post(enviornmentAperture.apiUrl + 'api/v1/allocations/addAllocationHr', payload,)
      .pipe(map((response: HttpResponse<any>) => {
        return response;
      }));
  }

  getReportingManagerDetails (resourceCode): Observable<any> {
    const resourcesCodeDTO = {
      resourceCode: this.CommonService.encrypt(resourceCode)
    }
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/resources/getManagerDetails', resourcesCodeDTO, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }

  subBenchStatusPost(data): Observable<any> {
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/resources/subbench', data, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  getMyManagersInfo(resourceCode):Observable<any> {
    
    const resourcesCodeDTO = {
      resourceCode: this.CommonService.encrypt(resourceCode)
    }
    return this.http.post(enviornmentAperture.apiUrl + 'api/v1/additional/getMyManagersInfo', resourcesCodeDTO, {}).pipe(map((response: HttpResponse<any>) => {
      return response;
    }));
  }
  uploadLeaveData(payload): Observable<any> {
    let formData = new FormData();
    let httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'multipart/form-data; charset=UTF-8'})
        };
        
    if (payload.file !== null) {
        formData.append('file',payload.file);
    }
   return this.http.post(enviornmentAperture.apiUrl + 'api/v1/designations/bulkUpdateDesignations', formData, { responseType: 'blob'}).pipe(map((response) =>   {
        return response;
    }));
}
}
