import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ViewAccountService } from './view-account.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { RemoveAccountConfirmDialogComponent } from '../../../app/dialog/remove-account-confirm-dialog/remove-account-confirm-dialog.component';
import { Location, DatePipe } from '@angular/common';
import { StatementOfWorkService } from '../../statement-of-work/statement-of-work.service';
import { DEFAULT_DATE_FORMAT } from '../../app.constants';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
export const DATE_FORMATE = 'yyyy-MM-dd';
import * as intlTelInput from 'intl-tel-input';
import { Title } from '@angular/platform-browser';
// import * as _ from 'underscore';
import * as _ from 'lodash'
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { PickDateAdapter, PICK_FORMATS } from 'app/validators/validator';
import * as moment from 'moment';
import { CommonDataService } from 'app/common-data.service';
@Component({
    selector: 'jhi-view-account',
    templateUrl: './view-account.component.html',
    styleUrls: ['./view-account.component.css'],
    entryComponents: [RemoveAccountConfirmDialogComponent],
    providers: [{ provide: DateAdapter, useClass: PickDateAdapter }, { provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS }]
})
export class ViewAccountComponent implements OnInit {
    isViewMode = true;
    showAllocationFlag: boolean;
    locationList: Array<any> = [];
    actualLocationList: Array<any> = [];
    accountCode: string;
    accountManagerList: Array<any> = [];
    actualAccountManagerList: Array<any> = [];
    isTimeTrackingAvailable: any = 'selected';
    selectedAccountManager: any;
    selectedAccountManagerAr: any;
    selectedAccountManagerName: any;
    selectedARManager:any;
    selectedLocation: any;
    customerGroup: any;
    customerEntity: string;
	gspannEntity: string;
    customerName: string;
    customerReportingManager: string;
    customerEmailId: any; // for email
    customerContactNumber: any; // for
    showMessage = false;
	public selectedCountry: FormControl = new FormControl();
	public gspanncompanyEntity: FormControl = new FormControl();
    currentUrl: string;
    actionMessage: string;
	isClicked = false;
    previousUrl: string;
    accountProjects: Array<any> = [];
    projectCall = true;
    historyCall = true;
    selectedCountryCode: any;
    custocarenumberCopy: any;
    minEndDate: any;
	locList: any;
    minStartDate: any;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    displayedColumns = ['projectCode', 'projectName', 'offshoreManagerName', 'country', 'projectStatusDescription'];
    dataSource;
    historyData: any;
    resourcesList: any = [];
    timeOut = null;
    searchTableData: any;
    searchTableDataPage: any;
    tableDataParams: Array<any>;
    accountDocuments: Array<any>;
    accountDocumentsView = true;
    resumeName = [];
    resumeData = [];
    prevresumeData = [];
    resumeNameList = [];
    resumeLengthFlag = false;
    resumeSizeFlag = false;
    files: FileList;
    load=false;
    paginationSelection: number;
    public reenableButton = new EventEmitter<boolean>(false);
    pagination: Array<any> = [2, 4, 6, 8, 10, 12, 14, 16];
    page: number;
    datePipe = new DatePipe('en-US');
    ifSow = false;
    panelOpenState = false;
    requestPayload: any = {};
    DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
    msaForm: FormGroup;
    checked: boolean;
    iti: any;
    msaStartDate: any;
    msaEndDate: any;
    maxlastsow: any;
	gspannEntityList: any;
	selectedCountryName: any;
    checkedsow: boolean = false;
    maxEndsowdate: any;
    errormessage: string = '';
    mutualerror: boolean = false;
    isFileuploading: boolean = false;
    incorrectFileFormat: boolean = false;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private viewAccountService: ViewAccountService,
        public dialog: MatDialog,
        private location: Location,
        public fb: FormBuilder,
        private sowService: StatementOfWorkService,
        private titleService: Title,
        private commonService: CommonDataService
    ) {
        this.titleService.setTitle('aperture - View Account');
        let previousUrl = localStorage.getItem('previousUrl');
        if (previousUrl === 'createaccount') {
            this.showMessage = true;
            localStorage.setItem('previousUrl', 'null');
            this.actionMessage = 'Your Account Has Successfully Been Added';
        }
    }

    ngOnInit() {
        this.msaForm = this.fb.group({
            msaStartDate: new FormControl('', [Validators.required]),
            msaEndDate: new FormControl(''),
        });
        let accountCode = this.commonService.accountCode;
        this.page = 0;
        this.paginationSelection = 2;
        this.getAccount();
        this.msaForm.disable();
        this.viewAccountService.getLocationAndAccountManagerList(accountCode).subscribe(data => {
            this.locationList = data.locationsList;
            this.actualLocationList = data.locationsList;
            this.accountManagerList = data.accountManagerList;
            this.actualAccountManagerList = data.accountManagerList;
			this.gspannEntityList = data.gspannEntityList;
			let entitiesSort = this.gspannEntityList.sort((c, d) => c.value.toLowerCase().localeCompare(d.value.toLowerCase()));

        });
    }
    checkstate(event) {
        this.mutualerror = false;
        this.checked = event.checked;
        if (this.checked) {
            this.msaForm.get('msaEndDate').clearValidators();
            this.msaForm.get('msaEndDate').reset();
            this.msaForm.get('msaEndDate').disable();
        } else {
            this.msaForm.get('msaEndDate').setValidators(Validators.required);
            this.msaForm.get('msaEndDate').enable();
        }
        if (!this.checkedsow && !this.checked) {
            this.msaForm.get('msaEndDate').setValue('');
            let currentdate = new Date();
            let day = currentdate.getDate();
            let month = currentdate.getMonth() + 1;
            let year = currentdate.getFullYear() + 100;
            let maxdate = year.toString() + '-' + month.toString() + '-' + day.toString();
            let today = moment();
            let mximumyear = moment(today).add(36500, 'days');
            let presentdate = mximumyear.format('yyyy-MM-DD');
            this.maxEndsowdate = presentdate;
        }
        if (this.checkedsow && !this.checked) {
            this.msaForm.get('msaEndDate').setValidators([]);
            this.msaForm.get('msaEndDate').disable();
            this.msaForm.get('msaEndDate').setValue(this.maxlastsow);
            this.maxEndsowdate = this.maxlastsow;
        }
        this.msaForm.get('msaEndDate').updateValueAndValidity();
    }
    checkstatesow(event) {
        this.mutualerror = false;
        if (event.checked) {
            this.viewAccountService.checkstatesow(this.accountCode).subscribe(response => {
                if (response) {
                    this.maxlastsow = _.cloneDeep(response);
                } else {
                    this.maxlastsow = _.cloneDeep('');
                }
                this.msaForm.get('msaEndDate').setValidators(Validators.required);
                this.msaForm.get('msaEndDate').enable();
                this.msaForm.get('msaEndDate').setValidators([]);
                this.msaForm.get('msaEndDate').setValue(this.maxlastsow);
                this.maxEndsowdate = this.maxlastsow;
            });
        }
        this.checkedsow = event.checked;
        if (!this.checkedsow) {
            this.msaForm.get('msaEndDate').setValidators(Validators.required);
            this.msaForm.get('msaEndDate').reset();
            let today = moment();
            let mximumyear = moment(today).add(36500, 'days');
            let presentdate = mximumyear.format('yyyy-MM-DD');
            this.maxEndsowdate = presentdate;
        }
        if (!this.checkedsow && !this.checked) {
            this.msaForm.get('msaEndDate').setValue('');
        }
        this.msaForm.get('msaEndDate').updateValueAndValidity();
    }
	entityChanged(event){
		if (event.value){
			this.selectedLocation = '';
			this.selectedCountry.setValidators([Validators.required]);
			this.selectedCountry.updateValueAndValidity();
			this.selectedCountry.markAsTouched();
			this.selectedCountry.markAsDirty();
		}
	}
    validateNumber(event) {
        let charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
    getAccount() {
        this.load=true;
        let accountCode = this.commonService.accountCode;
        this.mutualerror = false;
        this.viewAccountService.viewAccountDetails(accountCode).subscribe(response => {
            setTimeout(() => {
                this.load = false;
            }, 1000); 
            this.accountCode = response.accountCode;
            this.customerName = response.customerName ? response.customerName : '   ';
            this.customerEntity = response.customerEntity ? response.customerEntity : '  ';
			this.gspannEntity = response.gspannEntity ? response.gspannEntity : ' ';
            this.customerReportingManager = response.customerReportingManager ? response.customerReportingManager : '  ';
            this.isTimeTrackingAvailable = response.customerTimeTracking === false ? 'notselected' : 'selected';
            this.selectedLocation = response.countryCode;
            this.selectedAccountManager = response.accountManagerCode;
            this.selectedAccountManagerAr = response.arOwner;
            this.selectedAccountManagerName = response.accountManagerName ? response.accountManagerName : '  ';
            this.selectedARManager = response.arOwner ? response.arOwner : '  ';
            this.customerEmailId = response.customerEmailId;
            this.customerContactNumber = response.customerContactNumber.substring(response.customerContactNumber.indexOf('-') + 1, response.customerContactNumber.length);
            this.customerGroup = response.customerGroup;
            this.msaStartDate = this.datePipe.transform(response.mutualTermStartDate, DATE_FORMATE);
            this.minEndDate = response.mutualTermStartDate;
            this.msaEndDate = this.datePipe.transform(response.mutualTermEndDate, DATE_FORMATE);
            this.minStartDate = response.mutualTermEndDate;
            this.maxlastsow = response.mutualTermEndDate;
            this.checked = response.mutualTerminationFlag;
            this.checkedsow = response.lastSOWFlag;
            this.accountDocuments = response.documents;
            this.prevresumeData = JSON.parse(JSON.stringify(response.documents));
            this.custocarenumberCopy = response.customerContactNumber;
            if (this.msaEndDate===null &&this.isViewMode === false) {
                this.msaForm.get('msaEndDate').reset();
                this.msaForm.get('msaEndDate').disable();
            } else if (this.msaEndDate !== null && this.isViewMode === true) {
                this.msaForm.get('msaEndDate').disable();
            } else if (this.msaEndDate !== null && this.isViewMode === false) {
                this.msaForm.get('msaEndDate').enable();
            } else if (this.msaEndDate===null&&this.isViewMode===true) {
                this.msaForm.get('msaEndDate').disable();
            }
            if (this.checked && this.isViewMode === true) {
                this.msaForm.get('msaEndDate').setValue(null);
            }
            if (response.documents.length === 0) {
                this.accountDocumentsView = false;
            } else {
                this.accountDocumentsView = true;
            }
            if (this.iti) {
                this.iti.destroy();
            }
            let resourceCode = response.customerContactNumber;
            let xxx = document.querySelector('#xyzz');
            resourceCode = resourceCode.substring(0, resourceCode.indexOf('+'));
            if (!resourceCode) {
                resourceCode = 'IN';
            }
            let input = document.querySelector('#phone');
            this.iti = intlTelInput(input, {
                autoPlaceholder: 'off',
                initialCountry: resourceCode,
                separateDialCode: true,
                preferredCountries: ['IN', 'US'],
                // dropdownContainer: xxx
            });
            input.addEventListener('countrychange', e => {
                this.selectedCountryCode = this.iti.getSelectedCountryData();
            });
        });
        this.msaForm.reset();
        this.checked = false;
        (err=>{
            this.load = false;
        })
    }
    cancelEdit() {
        this.mutualerror = false;
        this.getAccount();
        this.isViewMode=true;
        this.enableCheckState();
        this.msaForm.disable();
        this.resumeName = [];
        this.resumeData = JSON.parse(JSON.stringify(this.prevresumeData));
        this.resumeNameList = [];
        this.resumeLengthFlag = false;
        this.resumeSizeFlag = false;
        if (this.prevresumeData.length) {
            for (let i = 0; i < this.prevresumeData.length; i++) {
                this.resumeNameList.push(this.prevresumeData[i].filename);
            }
        }
    }
    enableCheckState() {
        if (this.checked) {
            this.msaForm.get('msaEndDate').clearValidators();
            this.msaForm.get('msaEndDate').reset();
            this.msaForm.get('msaEndDate').disable();
        } else {
            this.msaForm.get('msaEndDate').setValidators(Validators.required);
            this.msaForm.get('msaEndDate').enable();
        }
        if (this.checkedsow) {
            this.msaForm.get('msaEndDate').setValidators([]);
            this.msaForm.get('msaEndDate').disable();
        }
        this.msaForm.get('msaEndDate').updateValueAndValidity();
    }
    changeListener($event): void {

        this.files = $event.target.files;
        console.log('this.files',this.files);
        for (let i = 0; i < this.files.length; i++) {
            let file: File = this.files[i];
            console.log('file',file);
            if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'application/pdf' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === 'application/zip' || file.type === 'application/x-zip-compressed' || file.type === 'application/msword' || file.type === 'application/ms-doc' || file.type === 'application/doc' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||file.type === 'application/vnd.ms-excel' || file.type === 'application/excel' || file.type === 'application/x-excel' || file.type === 'application/x-msexcel') {
                this.incorrectFileFormat = false;
                // return;
            } else {
                this.incorrectFileFormat = true;
                return;
            }
            if (file && file.size >= 10024000) {
                this.resumeSizeFlag = true;
                return;
            } else {
                this.resumeSizeFlag = false;
            }
            if (file && file.name.length >= 100) {
                this.resumeLengthFlag = true;
                return;
            } else {
                this.resumeLengthFlag = false;
            }
            this.resumeName[i] = file.name;
            this.resumeNameList.push(file.name);
            let myReader: FileReader = new FileReader();
            this.isFileuploading = true;
            myReader.onloadend = e => {
                this.resumeData.push({ data: myReader.result, filename: file.name });
                this.isFileuploading = false;
            };
            myReader.readAsDataURL(file);
        }
    }

    removeResume(i): void {
        this.resumeData.splice(i, 1);
        this.resumeNameList.splice(i, 1);
    }

    startDateChange(event) {
        this.minEndDate = event.value;
        this.minStartDate = event.value;
    }

    downLoadDocument(documents) {
        let byteString = atob(documents.data.split(',')[1]);
        let ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i += 1) {
            ia[i] = byteString.charCodeAt(i);
        }
        let blob = new Blob([ab], { type: 'application/docx' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob); // For IE browser
        }
        let linkElement = document.createElement('a');
        let url = window.URL.createObjectURL(blob);
        linkElement.setAttribute('href', url);
        linkElement.setAttribute('download', documents.filename);
        let clickEvent = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: false
        });
        linkElement.dispatchEvent(clickEvent);
    }

    goBack() {
        this.location.back();
    }

    public updateAccountDetails() {
        if (this.checkedsow && this.checked) {
            this.errormessage = "Mutual termination and Last SOW can't be selected simultaneously. Please select any one of them";
            this.mutualerror = true;
            return;
        }
		this.isClicked = true;
		setTimeout(()=>this.isClicked = false,2000);
        this.requestPayload['documents'] = [];
        if (this.resumeData.length && this.resumeNameList.length) {
            for (let i = 0; i < this.resumeData.length; i++) {
                this.requestPayload['documents'].push({ data: this.resumeData[i].data, filename: this.resumeData[i].filename, id: this.resumeData[i].id ? this.resumeData[i].id : 0 });
            }
        }
        if (!this.resumeData.length) {
            this.requestPayload['documents'] = [];
        }
        let cPhoneNo = '';
        if (!this.selectedCountryCode) {
            let a = this.custocarenumberCopy.substring(0, this.custocarenumberCopy.indexOf('-') + 1);
            cPhoneNo = a + this.customerContactNumber;
        }
        let data = {
            accountCode: this.accountCode,
            accountManagerCode: this.commonService.encrypt(this.selectedAccountManager),
            arOwner:this.commonService.encrypt(this.selectedAccountManagerAr),
            countryCode: this.selectedLocation,
			// countryName: this.selectedCountryName,
            customerEntity: this.customerEntity,
			gspannEntity: this.gspannEntity,
            customerName: this.customerName,
            customerGroup: this.customerGroup,
            customerEmailId: this.customerEmailId,
            mutualTermStartDate: this.datePipe.transform(this.msaForm.get('msaStartDate').value, DATE_FORMATE),
            mutualTermEndDate: this.datePipe.transform(this.msaForm.get('msaEndDate').value, DATE_FORMATE),
            mutualTerminationFlag: this.checked,
            customerContactNumber: this.selectedCountryCode ? this.selectedCountryCode.iso2 + '+' + this.selectedCountryCode.dialCode + '-' + this.customerContactNumber : cPhoneNo,
            customerReportingManager: this.customerReportingManager,
            // customerTimeTracking: this.isTimeTrackingAvailable === '0' ? false : true,
            customerTimeTracking: this.isTimeTrackingAvailable === 'notselected' ? false : true,
            documents: this.requestPayload['documents'],
            lastSOWFlag: this.checkedsow
        };
        this.viewAccountService.updateAccountDetails(data).subscribe(details => {
            this.reenableButton.emit(false);
            this.isViewMode = true;
            this.showMessage = true;
            this.getAccount();
            this.msaForm.disable();
            this.actionMessage = 'Changes Saved';
        });
		this.reenableButton.emit();
    }
    enableEditMode(): void {
        this.load=true;
        let accountCode = this.commonService.accountCode;
        this.resumeNameList = [];
        this.showMessage = false;
        this.isViewMode = false;
        this.msaForm.enable();
        this.enableCheckState();
        this.viewAccountService.getLocationAndAccountManagerList(accountCode).subscribe(data => {
            setTimeout(() => {
                this.load = false;
            }, 1000); 
            this.locationList = data.locationsList;
            this.actualLocationList = data.locationsList;
            this.accountManagerList = data.accountManagerList;
            this.actualAccountManagerList = data.accountManagerList;
			this.gspannEntityList = data.gspannEntityList;
			let entitiesSort = this.gspannEntityList.sort((c, d) => c.value.toLowerCase().localeCompare(d.value.toLowerCase()));

        });
		if (this.gspannEntity === ' '){
			this.gspannEntity = '';
			this.gspanncompanyEntity.setValidators(Validators.required);
			this.gspanncompanyEntity.updateValueAndValidity();
			}
        this.resumeData = this.accountDocuments;
        if (this.resumeData.length) {
            for (let i = 0; i < this.resumeData.length; i++) {
                this.resumeNameList.push(this.resumeData[i].filename);
            }
        }
        if (!this.checkedsow && !this.checked) {
            let today = moment();
            let mximumyear = moment(today).add(36500, 'days');
            let presentdate = mximumyear.format('yyyy-MM-DD');
            this.maxEndsowdate = presentdate;
        }
        (err=>{
            this.load = false;
        })
    }

    remove(): void {
        let dialogRef = this.dialog.open(RemoveAccountConfirmDialogComponent, {
            width: '412px',
            position: { top: '130px' },
            panelClass: "first-container-class"
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.viewAccountService.deleteAccount(this.accountCode).subscribe(data => {
                    this.reenableButton.emit(false);
                    this.router.navigate(['listaccounts'],{skipLocationChange: true});
                });
            }
        });
    }

    navigateToProjectPage() {
        this.router.navigate(['projects/add-project', { accountCode: this.accountCode }],{skipLocationChange: true});
    }

    search(filterValue: string) {
        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        this.dataSource.filter = filterValue;
    }
    checkResourceAccountActive() {
        this.resourcesList.forEach((element, i) => {
            if (element.emailId) {
                element.showResource = element.emailId.charAt(element.emailId.length - 1) === 'm';
            }
        });
    }
    searchResource(searchValue) {
        if (searchValue) {
            if (this.timeOut) {
                clearTimeout(this.timeOut);
            }
            this.timeOut = setTimeout(() => {
                this.viewAccountService.searchResource(searchValue).subscribe(resourceList => {
                    this.resourcesList = resourceList['_embedded']['itrack:resourceListItemList'];
                    this.checkResourceAccountActive();
                    this.resourcesList.forEach((element, i) => {
                        this.viewAccountService.getMethodRequest(element['_links']['image']['href']).subscribe((imgData: any) => {
                            this.resourcesList[i].image = imgData;
                        });
                    });
                });
            }, 1000);
        } else {
            this.resourcesList = [];
        }
    }

    searchResourceARManager(searchValue) {
        if (searchValue) {
            if (this.timeOut) {
                clearTimeout(this.timeOut);
            }
            this.timeOut = setTimeout(() => {
                this.viewAccountService.searchResource(searchValue).subscribe(resourceList => {
                    this.resourcesList = resourceList['_embedded']['itrack:resourceListItemList'];
                    this.checkResourceAccountActive();
                    this.resourcesList.forEach((element, i) => {
                        this.viewAccountService.getMethodRequest(element['_links']['image']['href']).subscribe((imgData: any) => {
                            this.resourcesList[i].image = imgData;
                        });
                    });
                });
            }, 1000);
        } else {
            this.resourcesList = [];
        }
    }
    onResourceSelection(event) {
        this.selectedAccountManager = event.option.value.code;
        this.selectedAccountManagerName = event.option.value.name;
    }
    onResourceSelectionARManager(event) {
        this.selectedAccountManagerAr = event.option.value.code;
        this.selectedARManager = event.option.value.name;
    }

    searchCountry(query: string) {
        if (!query) {
            this.locationList = this.actualLocationList;
            return;
        }
        let result = this.select(query, this.actualLocationList);
        this.locationList = result;
    }

    select(query: string, dataArray): string[] {
        let result: string[] = [];

        for (let list of dataArray) {
            if (list.value) {
                if (list.value.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                    result.push(list);
                }
            } else {
                if (list.toLowerCase().indexOf(query.toLowerCase()) > -1) {
                    result.push(list);
                }
            }
        }
        return result;
    }

    getSowsList() {
        if (this.accountCode && this.accountCode.length > 0) {
            this.tableDataParams = [];
            if (this.page >= 0) {
                this.tableDataParams.push({ key: 'page', value: this.page });
            }
            if (this.paginationSelection > 0) {
                this.tableDataParams.push({ key: 'size', value: this.paginationSelection });
            }
            this.tableDataParams.push({ key: 'account', value: this.accountCode });
            this.sowService.searchSowsListing(this.tableDataParams).subscribe(searchMD => {
                this.searchTableData = searchMD.content;
                let maxeenddate;
                for (let i = 0; i < this.searchTableData.length; i++) {
                    let item = this.searchTableData[i];
                    if (item && item.totalHoursSummary && item.totalHoursSummary.contractedHours) {
                        item.totalHoursSummary.contractedHours = this.numberWithCommas(item.totalHoursSummary.contractedHours);
                    }
                    if (item && item.billableHoursSummary && item.billableHoursSummary.contractedHours) {
                        item.billableHoursSummary.contractedHours = this.numberWithCommas(item.billableHoursSummary.contractedHours);
                    }
                    if (!maxeenddate) {
                        maxeenddate = moment(item.endDate);
                    }
                    if (maxeenddate < moment(item.endDate)) {
                        maxeenddate = moment(item.endDate)
                    }
                }
                if (!this.checkedsow && !this.checked) {
                    let today = moment();
                    let mximumyear = moment(today).add(36500, 'days');
                    let presentdate = mximumyear.format('yyyy-MM-DD');
                    this.maxEndsowdate = presentdate;
                }
                this.searchTableDataPage = searchMD.page;
            });
        }
    }
    jumpPageTo(pageNumber) {
        this.page = pageNumber;
        this.getSowsList();
    }

    encode(uri) {
        return encodeURIComponent(uri);
    }

    calPercentage(total: number, consumed: number) {
        let per = (total - (total - consumed)) / total * 100;
        if (!isFinite(per)) {
            return 0;
        }
        return Math.trunc(per);
    }
    numberWithCommas(x) {
        let parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
}
    setMyClasses(total: number, consumed: number, checkPer: number) {
        let result = this.calPercentage(total, consumed);
        let classes = {};
        if (result && result > checkPer) {
            classes = {
                'green-progress': false,
                'red-progress': true
            };
        } else if (result && result <= checkPer) {
            classes = {
                'green-progress': true,
                'red-progress': false
            };
        } else {
            classes = {
                'green-progress': true,
                'red-progress': false
            };
        }
        return classes;
    }

    onTabChange(event) {
        let accountCode = this.commonService.accountCode;
        this.ifSow = false;
        if (event.tab.textLabel === 'Project' && this.projectCall === true) {
            this.viewAccountService.getAllProjectsByAccountId(accountCode).subscribe(projects => {
                this.accountProjects = projects;
                this.dataSource = new MatTableDataSource(projects);
                this.dataSource.sort = this.sort;
                this.dataSource.paginator = this.paginator;
            });
            this.projectCall = false;
            this.ifSow = false;
        } else if (event.tab.textLabel === 'History' && this.historyCall === true) {
            this.viewAccountService.getAccountHistory(accountCode).subscribe(history => {
                this.historyData = history;
            });
            this.historyCall = true;
            this.ifSow = false;
        } else if (event.tab.textLabel === 'SOW') {
            this.getSowsList();
            this.ifSow = true;
        }
    }

    paginationFilter() {
        this.getSowsList();
    }
    formatNumber(value, code) {
        if(code) {
    
            if(value && !isNaN(Number(value))) {
                return Intl.NumberFormat(`en-${code === 'CAD' ? 'US' : code.slice(0,2)}`, {
                    style: 'currency',
                    currency: code,
                }).format(value);
            }
            if(value == 0) {
                return Intl.NumberFormat(`en-${code === 'CAD' ? 'US' : code.slice(0,2)}`, {
                    style: 'currency',
                    currency: code,
                }).format(0);
            }
        }else {
            return value;
        }
    }
    goToProjectPage(projectCode) {
        this.commonService.projectCode = projectCode;
        this.router.navigate(['/projects/view-project'], {skipLocationChange: true})
    }
}
